import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import BackgroundImage from 'gatsby-background-image'
import COLORS from '../constants/colors'

const useStyles = makeStyles(theme => ({
  fullImageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  fullImageWithPadding: {
    width: '100%',
    maxWidth: '2000px',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '64px',
    paddingBottom: '64px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '32px',
      paddingBottom: '32px',
    },
  },
  bgImage: {
    height: '60vh',
    width: '100%',
    objectFit: 'cover',
    backgroundSize: 'cover',
    minHeight: '300px',
  },
  doubleImageSection: {
    paddingBottom: '64px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  halfImageWrapperLeft: {
    paddingLeft: '40px',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  halfImageWrapperRight: {
    paddingLeft: '20px',
    paddingRight: '40px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
}))

const GreenMetalsImageSection = ({ images }) => {
  const classes = useStyles()

  if (images.length === 1) {
    // Full-width image with padding
    return (
      <div className={classes.fullImageContainer}>
        <div className={classes.fullImageWithPadding}>
          <BackgroundImage
            Tag="section"
            className={classes.bgImage}
            fluid={images[0].fluid}
          />
        </div>
      </div>
    )
  }

  // Two images case
  return (
    <Grid container>
      <Grid className={classes.doubleImageSection} item xs={12} md={6}>
        <div className={classes.halfImageWrapperLeft}>
          {images?.[0]?.fluid ? (
            <BackgroundImage
              Tag="section"
              className={classes.bgImage}
              fluid={images[0].fluid}
            />
          ) : (
            <p>No image available</p>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={classes.halfImageWrapperRight}>
          {images?.[1]?.fluid ? (
            <BackgroundImage
              Tag="section"
              className={classes.bgImage}
              fluid={images[1].fluid}
            />
          ) : (
            <p>No image available</p>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default GreenMetalsImageSection
