import React from 'react'
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import COLORS from '../constants/colors'
import FONTS from '../constants/fonts'
import Divider from './Divider'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contentContainer: {
    height: 'fit-content',
    backgroundColor: COLORS.WHITE,
    justifyContent: 'space-evenly',
  },
  sectionLeft: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 360,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      maxWidth: '100%',
      paddingTop: 36,
      height: 120,
    },
  },
  sectionRight: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      // height: 300,
      // paddingTop: 0,
      paddingTop: 40,
    },
  },
  text: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px',
    },
  },
  header: {
    fontSize: 38,
    marginTop: 36,
    maxWidth: 360,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXM,
      marginBottom: 0,
    },
  },
  description: {
    fontSize: 36,
    lineHeight: 1.2,
    fontWeight: FONTS.WEIGHT.LIGHT,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XM,
    },
  },
}))

const TextArea = ({ textLeft, textRight, smallText, withoutButtonDivider }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down(750))

  return (
    <div className={classes.root}>
      <Divider height={smallScreen ? 20 : 120} />
      <Grid container className={classes.contentContainer}>
        <Grid
          item
          xs={12}
          sm={smallText ? 5 : 4}
          className={classes.sectionLeft}
        >
          <h2 className={`${classes.text} ${classes.header}`}>{textLeft}</h2>
        </Grid>
        <Grid
          item
          xs={12}
          sm={smallText ? 5 : 6}
          className={classes.sectionRight}
        >
          <h3
            className={`${classes.text} ${classes.description}`}
            style={{
              fontSize: smallText ? '24px' : undefined, // Example smaller font size
            }}
          >
            {textRight}
          </h3>
        </Grid>
      </Grid>
      {!withoutButtonDivider && <Divider height={smallScreen ? 20 : 120} />}
    </div>
  )
}

export default TextArea
