import React from "react"
import BackgroundImage from "gatsby-background-image"
import { shape, oneOf, string } from "prop-types"
import { makeStyles } from "@material-ui/core"
import FONTS from "../constants/fonts"

const useStyles = makeStyles(theme => ({
  bigImageWrapper: {
    width: "100%",
    color: "white",
    height: '40vh',
    [theme.breakpoints.down("sm")]: { height: "50vh" },
  },
  bgImage: {
    height: "40vh",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: { height: "50vh" },
  },
  bgImageTextWrapper: {
    display: "flex",
    alignItems: "flex-end",
    height: "30vh",
    position: "absolute",
    bottom: 0,
    left: "5%",
    [theme.breakpoints.down("sm")]: { bottom: "10%", left: "5%", },
  },
  bgImageText: {
    fontSize: FONTS.SIZE.XXXL,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    lineHeight: 1,
    maxWidth: "65vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: FONTS.SIZE.L,
    },
  },
}))

const SubPageHeroBanner = ({ image, title, textPosition }) => {
  const classes = useStyles()

  return (
    <div className={classes.bigImageWrapper}>
      <BackgroundImage
        Tag="section"
        className={classes.bgImage}
        fluid={image}
        backgroundColor={`#040e18`}
      >
        <div
          className={classes.bgImageTextWrapper}
          style={
            textPosition === "Right"
              ? { justifyContent: "flex-end" }
              : { justifyContent: "flex-start" }
          }
        >
          <h2 className={classes.bgImageText}>{title}</h2>
        </div>
      </BackgroundImage>
    </div>
  )
}
SubPageHeroBanner.propTypes = {
  image: shape({ fluid: undefined }),
  textPosition: oneOf(["Left", "Right"]),
  title: string,
}

export default SubPageHeroBanner
