import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import COLORS from '../constants/colors'
import SubPageHeroBanner from '../components/SubPageHeroBanner'
import TextArea from '../components/TextArea'
import { Grid, makeStyles } from '@material-ui/core'
import BackgroundImage from 'gatsby-background-image'
import FONTS from '../constants/fonts'
import GreenMetalsImageSection from '../components/GreenMetalsImageSection'

// Component-specific styles
const useStyles = makeStyles(theme => ({
  // General container styles
  root: {
    flexGrow: 1,
  },
  contentContainer: {
    backgroundColor: COLORS.WHITE,
  },

  // Image and text section (full width grey background)
  graySectionFullWidth: {
    backgroundColor: COLORS.LIGHT_GREY,
    paddingTop: 64,
    paddingBottom: 64,
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    position: 'relative',
  },

  // Section for images that take up half width (left and right variations)
  halfImageWrapperLeft: {
    paddingLeft: '40px',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },

  // Style for full-width images (like in GreenMetalsImageSection)
  bgImage: {
    height: '60vh',
    width: '100%',
    objectFit: 'cover',
    backgroundSize: 'cover',
    minHeight: '300px',
  },

  // Style for larger images (hero or large image sections)
  halfImage: {
    height: '80vh',
    width: '100%',
    objectFit: 'cover',
  },

  // Title styles for image-text sections
  imageTitle: {
    fontSize: 36,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    lineHeight: 1.2,
    marginBottom: 32,
    whiteSpace: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.XM,
    },
  },

  // Paragraph text styles for image-text sections
  imageText: {
    lineHeight: 1.5,
    fontSize: 18,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.S,
      fontWeight: FONTS.WEIGHT.REGULAR,
    },
  },

  // Text container styles for image-text sections
  textContainer: {
    paddingLeft: '40px',
    paddingRight: '40px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },

  // Section for grey background, standard size
  graySection: {
    backgroundColor: COLORS.LIGHT_GREY,
    paddingTop: 64,
    paddingBottom: 64,
  },
}))

// Individual Section Components

const GreenMetalsTextSection = ({ title, description, smallText, classes }) => (
  <TextArea textLeft={title} textRight={description} smallText={smallText} />
)

const GreenMetalsImageTextSection = ({
  title,
  description,
  image,
  classes,
}) => {
  const formattedDescription = description.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ))

  return (
    <div className={classes.graySectionFullWidth}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <div className={classes.halfImageWrapperLeft}>
            <BackgroundImage
              Tag="section"
              className={classes.bgImage}
              fluid={image.fluid}
            />
          </div>
        </Grid>
        <Grid className={classes.textContainer} item xs={12} md={5}>
          <div>
            <h4 className={classes.imageTitle}>{title}</h4>
            <p className={classes.imageText}>{formattedDescription}</p>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

// Main Page Component
const OurProjects = ({ data }) => {
  const classes = useStyles()
  const orderedData =
    data.allContentfulGreenMetalsOrderedList.edges[0].node.items

  const renderSection = (section, index) => {
    switch (section.__typename) {
      case 'ContentfulGreenMetalsTextSection':
        return (
          <GreenMetalsTextSection
            key={index}
            title={section.title}
            description={section.description.description}
            classes={classes}
            smallText={true}
          />
        )
      case 'ContentfulGreenMetalsImageSection':
        return <GreenMetalsImageSection images={section.images} />
      case 'ContentfulGreenMetalsImageTextSection':
        return (
          <GreenMetalsImageTextSection
            key={index}
            title={section.title}
            description={section.description.description}
            image={section.image}
            classes={classes}
          />
        )
      default:
        return null
    }
  }

  return (
    <Layout>
      <SubPageHeroBanner
        textPosition="Left"
        image={data.solutions.childImageSharp.fluid}
        title="Our projects"
      />
      <div className={classes.root}>
        <Grid container className={classes.contentContainer}>
          {orderedData.map((section, index) => renderSection(section, index))}
        </Grid>
      </div>
    </Layout>
  )
}

export default OurProjects

// GraphQL Query
export const query = graphql`
  query SolutionPageQuery {
    solutions: file(relativePath: { eq: "sub_hero_solutions_2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulGreenMetalsOrderedList(
      filter: { listId: { eq: "solutionPageContent" } }
    ) {
      edges {
        node {
          items {
            __typename
            ... on ContentfulGreenMetalsTextSection {
              identifier
              title
              description {
                description
              }
            }
            ... on ContentfulGreenMetalsImageSection {
              identifier
              images {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
            ... on ContentfulGreenMetalsImageTextSection {
              identifier
              title
              description {
                description
              }
              image {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
