import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  firstStyle: {
    height: props => props.height,
  },
  secondStyle: {
    color: props => props.color,
  },
})

function Divider({ ...props }) {
  const { firstStyle, secondStyle } = useStyles(props)
  return <div className={`${firstStyle} ${secondStyle}`}></div>
}

export default Divider
